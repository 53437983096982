import Base from '../../_config/base';

class Video extends Base {
  constructor(el) {
    super(el);

    const playBtn = el.querySelector('.play__button');
    const video = el.querySelector('video');

    if (video) {
      const exitHandler = () => {
        if (document.fullscreenElement === null) {
          video.removeAttribute('controls');
          video.pause();
          video.currentTime = 0;
          video.load();
          playBtn.classList.add('show');
          document.exitFullscreen();
        }
      };

      const playToggle = () => {
        playBtn.classList.remove('show');

        video.play();
        video.setAttribute('controls', true);

        // if (video.mozRequestFullScreen) {
        //   video.mozRequestFullScreen();
        // }

        // if (video.webkitRequestFullScreen) {
        //   video.webkitRequestFullScreen();
        // }
      };

      if (playBtn) {
        playBtn.addEventListener('click', () => playToggle());
      }

      video.addEventListener('pause', () => exitHandler());

      document.addEventListener('fullscreenchange', () => exitHandler());
      document.addEventListener('mozfullscreenchange', () => exitHandler());
      document.addEventListener('MSFullscreenChange', () => exitHandler());
      document.addEventListener('webkitfullscreenchange', () => exitHandler());
    }
  }
}

Video.className = 'Video';
export default Video;
