/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { Loader } from '@googlemaps/js-api-loader';
import Base from '../../_config/base';

class Map extends Base {
  constructor(el) {
    super(el);

    const maps = document.querySelectorAll('.map__block');

    maps.forEach((mapItem) => {
      this.markers = [];

      const centerX = +mapItem.getAttribute('data-x');
      const centerY = +mapItem.getAttribute('data-y');

      const loader = new Loader({
        apiKey: 'AIzaSyDMctRX9CcP9ahFykNkjTKgTAYCYObQ5iM',
        version: 'weekly',
        libraries: ['places'],
      });

      const mapStyle = {
        center: {
          lat: centerX,
          lng: centerY,
        },
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [{
              saturation: '-100',
            },
            {
              lightness: '0',
            },
            {
              gamma: '0.65',
            },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [{
              visibility: 'off',
            }],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [{
              visibility: 'off',
            }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{
              visibility: 'off',
            }],
          },
        ],
      };

      // eslint-disable-next-line prefer-arrow-callback
      loader.load().then((google) => {
        this.google = google;
        this.map = new google.maps.Map(mapItem, {
          zoom: 13,
          ...mapStyle,
        });

        this.infoWindow = new google.maps.InfoWindow();

        const marker = new google.maps.Marker({
          position: {
            lat: centerX,
            lng: centerY,
          },
          map: this.map,
          icon: new google.maps.MarkerImage(
            './assets/images/pin.svg',
            null,
            new google.maps.Point(0, 0),
          ),
          visible: true,
          category: 'Company',
          title: '<a href="https://www.google.com/maps/search/?api=1&query=Immovesta" target="_blank">Immovesta</a>',
        });
        this.markers.push(marker);
      });
    });
  }
}

Map.className = 'Map';
export default Map;
