/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import mixitup from 'mixitup';
import mixitupMultifilter from '../../mixitup/mixitup-multifilter';
import Base from '../../_config/base';

mixitup.use(mixitupMultifilter);

const config = {
  animation: {
    effects: 'fade scale stagger(50ms)',
  },
  selectors: {
    target: '.mix',
  },
  controls: {
    enable: true,
    toggleDefault: 'all',
  },
  debug: {
    enable: true,
  },
};

const containerEl = document.querySelector('.filteredObjectsContainer');
const initTrigger = document.getElementById('initSaleObjectsStatus');

let mixer;
if (containerEl) {
  mixer = mixitup(containerEl, config);
  initTrigger.click();
}

class Saleobjectsfilter extends Base {
  constructor(el) {
    super(el);

    this.filterTrigger = el.querySelectorAll('.filter__container_project');
    this.filterTitle = el.querySelector('.projectFilter__title');

    this.filterTrigger.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const triggerTitle = trigger.innerText;
        this.filterTitle.innerText = triggerTitle;
      });
    });
  }
}

Saleobjectsfilter.className = 'Saleobjectsfilter';
export default Saleobjectsfilter;
