import Base from '../../_config/base';

class Attachment extends Base {
  constructor(el) {
    super(el);

    this.formAttachmentButton = el.querySelector('.form-attachment');
    this.fileName = el.querySelector('.file-name');
    this.attachmentPlaceholder = el.querySelector('.attachment-placeholder');
    this.clearAttachmentButton = el.querySelector('.clear-attachment');
    this.addAttachment = el.querySelector('.add-attachment');
    this.addDocLabel = el.querySelector('.add-doc');

    this.formAttachmentButton.addEventListener('change', this.handleFileChange.bind(this));
    this.clearAttachmentButton.addEventListener('click', this.handleClearAttachment.bind(this));
  }

  handleFileChange() {
    this.fileName.textContent = this.formAttachmentButton.files[0].name;
    this.addDocLabel.classList.add('d-none');
    this.addAttachment.classList.add('d-none');
    this.formAttachmentButton.classList.add('disabled');
    this.clearAttachmentButton.classList.remove('d-none');
    this.attachmentPlaceholder.classList.remove('disabled');
  }

  handleClearAttachment() {
    this.fileName.textContent = '';
    this.formAttachmentButton.value = '';
    this.addDocLabel.classList.remove('d-none');
    this.addAttachment.classList.remove('d-none');
    this.formAttachmentButton.classList.remove('disabled');
    this.clearAttachmentButton.classList.add('d-none');
    this.attachmentPlaceholder.classList.add('disabled');
  }
}

Attachment.className = 'Attachment';
export default Attachment;
