import Base from '../../_config/base';

class Alerts extends Base {
  constructor(el) {
    super(el);

    const linkTrigger = el.querySelector('.alerts-open');
    const closeAlert = el.querySelector('.alerts-close');

    const alert = el.querySelector('.alerts__alert');

    linkTrigger.addEventListener('click', () => {
      alert.classList.add('alert-show');
    });

    closeAlert.addEventListener('click', () => {
      alert.classList.remove('alert-show');
    });
  }
}

Alerts.className = 'Alerts';
export default Alerts;
