import Base from '../../_config/base';

class Form extends Base {
  constructor(el) {
    super(el);

    const message = el.querySelectorAll('.contactForm__message');
    if (message[0]) {
      setTimeout(() => {
        message[0].classList.add('hide');
      }, 3500);
    }
  }
}

Form.className = 'Form';
export default Form;
