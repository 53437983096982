/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import mixitup from 'mixitup';
import mixitupMultifilter from '../../mixitup/mixitup-multifilter';
import Base from '../../_config/base';

mixitup.use(mixitupMultifilter);

const config = {
  multifilter: {
    enable: true,
    logicBetweenGroups: 'or',
    logicWithinGroup: 'and',
  },
  animation: {
    effects: 'fade scale stagger(10ms)',
  },
  selectors: {
    target: '.mix',
  },
  controls: {
    enable: true,
    toggleDefault: 'projfilter-actual',
  },
  debug: {
    enable: true,
  },
};

const containerEl = document.querySelector('.filteredContainer');
const initTrigger = document.getElementById('initProjStatus');
const allProjectsTrigger = document.getElementById('allProjtrigger');
const specificProjectsTriggers = document.querySelectorAll('.specificProjects__trigger');
const filterTitle = document.querySelector('.projectFilter__title');

let mixer;
if (containerEl) {
  mixer = mixitup(containerEl, config);
  mixer.setFilterGroupSelectors('allProjects', ['.projfilter-actual', '.projfilter-planned', '.projfilter-rent', '.projfilter-reference', '.projfilter-finished']);

  allProjectsTrigger.parentElement.addEventListener('click', () => {
    if (!allProjectsTrigger.classList.contains('mixitup-control-active')) {
      specificProjectsTriggers.forEach((specificProjectTrigger) => {
        if (specificProjectTrigger.classList.contains('mixitup-control-active')) {
          specificProjectTrigger.click();
        }
        specificProjectTrigger.classList.remove('mixitup-control-active');
        specificProjectTrigger.removeAttribute('checked');
      });
    }
    allProjectsTrigger.click();
    filterTitle.innerText = allProjectsTrigger.parentElement.innerText;
  });

  specificProjectsTriggers.forEach((specificProjectTrigger) => {
    specificProjectTrigger.addEventListener('click', () => {
      if (!specificProjectTrigger.classList.contains('mixitup-control-active')) {
        if (allProjectsTrigger.classList.contains('mixitup-control-active')) {
          allProjectsTrigger.removeAttribute('checked');
          // allProjectsTrigger.removeAttribute('disabled');
          allProjectsTrigger.click();
        }
      } else {
        specificProjectTrigger.classList.remove('mixitup-control-active');
        let isActiveTriggers = false;
        specificProjectsTriggers.forEach((specificTrigger) => {
          if (specificTrigger.classList.contains('mixitup-control-active')) {
            isActiveTriggers = true;
          }
        });
        if (!isActiveTriggers) {
          allProjectsTrigger.setAttribute('checked', 'checked');
          allProjectsTrigger.click();
        }
      }
    });
  });
}

class Projectfilter extends Base {
  constructor(el) {
    super(el);

    this.filterTrigger = el.querySelectorAll('.filter__container_project');
    this.filterTitle = el.querySelector('.projectFilter__title');
    this.filterTrigger = el.querySelectorAll('.filter__container_project');
    this.filterTitle = el.querySelector('.projectFilter__title');
    initTrigger.click();

    // Set title for filter according to active triggers
    specificProjectsTriggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        setTimeout(() => {
          this.filterTitle.innerText = '';
          this.filterTrigger.forEach((btn) => {
            const input = btn.querySelector('input');

            const triggerTitle = btn.innerText;

            if (input.classList.contains('mixitup-control-active')) {
              this.filterTitle.innerText = this.filterTitle.innerText.length > 0
                ? `${this.filterTitle.innerText}, ${triggerTitle}`
                : `${triggerTitle}`;
            }
          });
        }, 10);
      });
    });
  }
}

Projectfilter.className = 'Projectfilter';
export default Projectfilter;
