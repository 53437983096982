import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Objectpageslider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      freeMode: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        renderFraction: (currentClass, totalClass) => `<span class="${currentClass}"></span><span class="space-line">/</span><span class="${totalClass}"></span>`,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Objectpageslider.className = 'Objectpageslider';
export default Objectpageslider;
