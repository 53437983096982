import Base from '../../_config/base';

class Accordion extends Base {
  constructor(el) {
    super(el);
    const allItems = [...el.querySelectorAll('.accordion__item')];

    allItems.forEach((item) => {
      const itemHeader = item.querySelector('.accordion__title');
      itemHeader.onclick = () => {
        if (item.classList.contains('accordion__open')) {
          allItems.forEach((accordionItem) => accordionItem.classList.remove('accordion__open'));
        } else {
          allItems.forEach((accordionItem) => accordionItem.classList.remove('accordion__open'));
          item.classList.add('accordion__open');
        }
      };
    });
  }
}

Accordion.className = 'Accordion';
export default Accordion;
