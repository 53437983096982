import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);

    this.hamburger = el.querySelector('#hamburger-icon');
    this.hamburgerMenu = el.querySelector('.hamburger-menu');
    this.header = document.querySelector('.header');
    this.body = document.getElementById('body');

    this.hamburger.onclick = (e) => {
      e.preventDefault();
      this.hamburger.classList.toggle('active');
      this.body.classList.toggle('overflow-hidden');
      el.classList.toggle('active');
      this.hamburgerMenu.classList.remove('active');
    };

    let lastScrollTop = 0;

    window.addEventListener('scroll', () => {
      if (this.header.classList.contains('active')) {
        return;
      }
      const position = window.scrollY || document.documentElement.scrollTop;
      if (position > lastScrollTop) {
        // downscroll code
        this.header.classList.add('hide');
      } else if (position < lastScrollTop) {
        // upscroll code
        this.header.classList.remove('hide');
      }
      lastScrollTop = position <= 0 ? 0 : position;
    }, false);
  }
}

Header.className = 'Header';
export default Header;
