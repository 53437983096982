import Base from '../../_config/base';

class Range extends Base {
  constructor(el) {
    super(el);
    // Single Range
    // -----------------------------------------------------------------
    const rangeInput = el.querySelector('.range-input');
    const rangeValue = el.querySelector('.range-value');

    if (rangeInput !== null) {
      rangeInput.oninput = (e) => {
        const { target } = e;
        const { min } = target;
        const { max } = target;
        const val = target.value;

        // display current Value
        rangeValue.innerHTML = val;

        const precent = ((val - min) * 100) / (max - min);
        target.style.backgroundSize = `${precent}% 100%`;
      };
    }
    // -----------------------------------------------------------------

    // Double Range
    // -----------------------------------------------------------------
    const upperSlider = el.querySelector('#upper');
    const lowerSlider = el.querySelector('#lower');
    let lowerVal = parseInt(lowerSlider.value, 10);
    let upperVal = parseInt(upperSlider.value, 10);

    if (upperSlider !== null) {
      upperSlider.oninput = (e) => {
        const step = parseInt(e.target.attributes[6].value, 10);

        lowerVal = parseInt(lowerSlider.value, 10);
        upperVal = parseInt(upperSlider.value, 10);

        if (upperVal < lowerVal + step) {
          lowerSlider.value = upperVal - step;
        }
        // display current Value
        rangeValue.innerHTML = `${lowerSlider.value} bis ${upperSlider.value}`;
      };

      lowerSlider.oninput = (e) => {
        const step = parseInt(e.target.attributes[6].value, 10);

        lowerVal = parseInt(lowerSlider.value, 10);
        upperVal = parseInt(upperSlider.value, 10);

        if (lowerVal > upperVal - step) {
          upperSlider.value = lowerVal + step;
        }
        // display current Value
        rangeValue.innerHTML = `${lowerSlider.value} bis ${upperSlider.value}`;
      };
    }
    // -----------------------------------------------------------------
  }
}

Range.className = 'Range';
export default Range;
