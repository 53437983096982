/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import mixitup from 'mixitup';
import mixitupMultifilter from '../../mixitup/mixitup-multifilter';
import Base from '../../_config/base';

mixitup.use(mixitupMultifilter);

const config = {
  animation: {
    effects: 'fade scale stagger(50ms)',
  },
  selectors: {
    target: '.mix',
  },
  controls: {
    enable: true,
    toggleDefault: 'all',
  },
  debug: {
    enable: true,
  },
  callbacks: {
    onMixStart: (state, futureState) => {
      futureState.show.forEach((element, index) => {
        if (index % 2 === 0) {
          // element.style.background = '#FFFFFF';
          element.classList.remove('newsList__item_odd');
          element.classList.add('newsList__item_even');
        }
        if (index % 2 === 1) {
          // element.style.background = 'rgba(107, 16, 44, 0.05)';
          element.classList.remove('newsList__item_even');
          element.classList.add('newsList__item_odd');
        }
      });
    },
  },
};

const containerEl = document.querySelector('.filteredNewsContainer');
const initTrigger = document.getElementById('initNewsStatus');

let mixer;
if (containerEl) {
  mixer = mixitup(containerEl, config);
  initTrigger.click();
}

class Newsfilter extends Base {
  constructor(el) {
    super(el);

    this.filterTrigger = el.querySelectorAll('.filter__container_project');
    this.filterTitle = el.querySelector('.projectFilter__title');

    this.filterTrigger.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const triggerTitle = trigger.innerText;
        this.filterTitle.innerText = triggerTitle;
      });
    });
  }
}

Newsfilter.className = 'Newsfilter';
export default Newsfilter;
