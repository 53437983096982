import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Newsslider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      freeMode: true,
      pagination: {
        el: '.newsswiper-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.newsswiper-button-next',
        prevEl: '.newsswiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Newsslider.className = 'Newsslider';
export default Newsslider;
