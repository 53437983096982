import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Slider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');
    // const fullscreen = el.querySelector('.slider-fullscreen');
    // const fullscreenClose = el.querySelector('.slider__fullscreen--bg');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      freeMode: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.2,
          spaceBetween: 24,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 1.3,
          spaceBetween: 48,
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 2.3,
          spaceBetween: 96,
        },
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);

    // fullscreen.onclick = () => {
    //   el.classList.toggle('slider__fullscreen');
    // };

    // fullscreenClose.onclick = () => {
    //   el.classList.remove('slider__fullscreen');
    // };
  }
}

Slider.className = 'Slider';
export default Slider;
