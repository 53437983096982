/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import mixitup from 'mixitup';
import mixitupMultifilter from '../../mixitup/mixitup-multifilter';
import Base from '../../_config/base';

mixitup.use(mixitupMultifilter);
const temContainerEl = document.querySelector('.filteredTeamContainer');
const initTrigger = document.getElementById('initTeamStatus');
const specificTeamsTriggers = document.querySelectorAll('.specificTeams__trigger');

const teamMixerConfig = {
  multifilter: {
    enable: true,
    logicBetweenGroups: 'or',
    logicWithinGroup: 'and',
  },
  animation: {
    effects: 'fade scale stagger(50ms)',
  },
  selectors: {
    target: '.mix',
  },
  controls: {
    enable: true,
    toggleDefault: 'all',
  },
  debug: {
    enable: true,
  },
  callbacks: {
    onMixStart: (state, futureState) => {
      // console.log('state', state);

      if (window.innerWidth >= 768) {
        futureState.show.forEach((element, index) => {
          if (index % 2 === 0) {
            element.style.marginTop = '0px';
          }
          if (index % 2 === 1) {
            element.style.marginTop = '56px';
          }
        });
      }

      if (window.innerWidth >= 1024) {
        futureState.show.forEach((element, index) => {
          if (index % 3 === 0) {
            element.style.marginTop = '80px';
          }
          if (index % 3 === 1) {
            element.style.marginTop = '160px';
          }
          if (index % 3 === 2) {
            element.style.marginTop = '0px';
          }
        });
      }
    },
  },
};

let teamMixer;
if (temContainerEl) {
  teamMixer = mixitup(temContainerEl, teamMixerConfig);
  initTrigger.click();

  initTrigger.parentElement.addEventListener('click', () => {
    if (!initTrigger.classList.contains('mixitup-control-active')) {
      specificTeamsTriggers.forEach((specificTeamsTrigger) => {
        if (specificTeamsTrigger.classList.contains('mixitup-control-active')) {
          specificTeamsTrigger.removeAttribute('checked');
          specificTeamsTrigger.click();
        }
      });
      initTrigger.click();
    }
  });

  specificTeamsTriggers.forEach((specificTeamsTrigger) => {
    specificTeamsTrigger.addEventListener('click', () => {
      if (!specificTeamsTrigger.classList.contains('mixitup-control-active')) {
        if (initTrigger.classList.contains('mixitup-control-active')) {
          initTrigger.removeAttribute('checked');
          initTrigger.click();
        }
      } else {
        specificTeamsTrigger.classList.remove('mixitup-control-active');
        let isActiveTriggers = false;
        specificTeamsTriggers.forEach((specificTrigger) => {
          if (specificTrigger.classList.contains('mixitup-control-active')) {
            isActiveTriggers = true;
          }
        });
        if (!isActiveTriggers) {
          initTrigger.setAttribute('checked', 'checked');
          initTrigger.click();
        }
      }
    });
  });
}

class Teamfilter extends Base {
  constructor(el) {
    super(el);

    this.filterTrigger = el.querySelectorAll('.filter__container_project');
    this.filterTitle = el.querySelector('.projectFilter__title');

    // Set title for filter according to active triggers
    this.filterTrigger.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        setTimeout(() => {
          this.filterTitle.innerText = '';
          this.filterTrigger.forEach((btn) => {
            const input = btn.querySelector('input');

            const triggerTitle = btn.innerText;

            if (input.classList.contains('mixitup-control-active')) {
              this.filterTitle.innerText = this.filterTitle.innerText.length > 0
                ? `${this.filterTitle.innerText}, ${triggerTitle}`
                : `${triggerTitle}`;
            }
          });
        }, 0);
      });
    });
  }
}

Teamfilter.className = 'Teamfilter';
export default Teamfilter;
