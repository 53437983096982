import Button from './01-atoms/button/button';
import Attachment from './01-atoms/inputs/attachment';
import Range from './01-atoms/inputs/range';
import Select from './01-atoms/inputs/select';
import Video from './01-atoms/video/video';
import Share from './02-molecules/share/share';
import Accordion from './02-molecules/accordion/accordion';
import Alerts from './02-molecules/alerts/alerts';
import Popup from './02-molecules/popup/popup';
import NewsTeaser from './02-molecules/newsTeaser/newsTeaser';
import Slider from './02-molecules/slider/slider';
import Map from './02-molecules/map/map';
import Gallery from './02-molecules/slider/gallery';
import Objectpageslider from './02-molecules/slider/objectpageslider';
import Newsslider from './02-molecules/slider/newsslider';
import Teasers from './02-molecules/threeTeaser/teasers';
// import Filter from './02-molecules/filter/filter';
import Projectfilter from './02-molecules/filter/projectFilter';
import Teamfilter from './02-molecules/filter/teamFilter';
import Newsfilter from './02-molecules/filter/newsFilter';
import Dialogues from './02-molecules/dialogues/dialogues';
import Header from './03-organisms/header/header';
import Saleobjectsfilter from './02-molecules/filter/saleobjectsfilter';
import Form from './02-molecules/contactForm/form';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Button = Button.init();
  Component.Range = Range.init();
  Component.Dialogues = Dialogues.init();
  Component.Share = Share.init();
  Component.Accordion = Accordion.init();
  Component.Alerts = Alerts.init();
  Component.Popup = Popup.init();
  Component.Slider = Slider.init();
  Component.Gallery = Gallery.init();
  Component.Newsslider = Newsslider.init();
  Component.Attachment = Attachment.init();
  Component.Select = Select.init();
  Component.Header = Header.init();
  Component.Teasers = Teasers.init();
  // Component.Filter = Filter.init();
  Component.Projectfilter = Projectfilter.init();
  Component.Teamfilter = Teamfilter.init();
  Component.Newsfilter = Newsfilter.init();
  Component.Video = Video.init();
  Component.NewsTeaser = NewsTeaser.init();
  Component.Map = Map.init();
  Component.Saleobjectsfilter = Saleobjectsfilter.init();
  Component.Objectpageslider = Objectpageslider.init();
  Component.Form = Form.init();
});
