import Base from '../../_config/base';

class NewsTeaser extends Base {
  constructor(el) {
    super(el);

    const leadBlock = el.querySelectorAll('.newsTeaser__item_description p');
    const numberOfCarachters = 180;

    leadBlock.forEach((block) => {
      const numberOfChildren = block.getElementsByTagName('*:not(br)').length;
      if (numberOfChildren === 0) {
        if (block.innerText.length > numberOfCarachters) {
          block.innerText = `${block.innerText.slice(0, numberOfCarachters)} ...`;
        }
      } else {
        let charachterCount = 0;
        for (let i = 0; i < numberOfChildren; i += 1) {
          if (charachterCount >= numberOfCarachters) block.children[i].innerText = '';
          if (block.children[i].innerText.length + charachterCount > numberOfCarachters) {
            block.children[i].innerText = `${block.children[i].innerText.slice(0, numberOfCarachters - charachterCount)} ...`;
            charachterCount += block.children[i].innerText.length;
          } else {
            charachterCount += block.children[i].innerText.length;
          }
        }
      }
    });
  }
}

NewsTeaser.className = 'NewsTeaser';
export default NewsTeaser;
