import Base from '../../_config/base';

class Share extends Base {
  constructor(el) {
    super(el);

    this.openShare = el.querySelector('.share-button');
    this.share = el.querySelector('.share__open');
    this.closeShare = el.querySelector('.close-share');

    this.openShare.onclick = () => {
      this.share.classList.remove('d-none');
      this.openShare.classList.add('d-none');
    };
    this.closeShare.onclick = () => {
      this.share.classList.add('d-none');
      this.openShare.classList.remove('d-none');
    };
  }
}

Share.className = 'Share';
export default Share;
